@import "params";
@import "fonts";
@import "components/dropdown";
@import "components/header";
@import "components/Hint";
@import "components/dialog";
@import "components/imageBox";
@import "components/CollectionImageFast";
@import "components/HeaderUserArea";
@import "components/WechatLogin";
@import "components/pagination";

@font-face {
  font-family: "SourceHanSansSC-Regular";
  src: url('https://zt.bigbigwork.com/p/fonts/SourceHanSansSC-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SourceHanSansSC-Medium";
  src: url('https://zt.bigbigwork.com/p/fonts/SourceHanSansSC-Medium.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SourceHanSansSC-Bold";
  src: url('https://zt.bigbigwork.com/p/fonts/SourceHanSansSC-Bold.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TsangerJinKai02-W01";
  src: url('#{$cdn_font_path}/p/fonts/TsangerJinKai02-W01.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TsangerJinKai02-W03";
  src: url('#{$cdn_font_path}/p/fonts/TsangerJinKai02-W03.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
* {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #AAA;
  border-radius: 4px;
  display: inline-block;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

html {
  -webkit-text-size-adjust: none; /*处理webkit内核浏览器默认最小字体为12px的问题.(在新版chrome浏览器里已废除次方法，所以无效)*/
  //height:100%;
  //overflow:auto;
  //margin: 0;
}

body {
  padding: 0;
  background-color: #fff;
  color: $font_color;
  font-size: 0.875em;
  line-height: 1.42858;
  font-family: "SourceHanSansSC-Regular", "Microsoft Yahei", "微软雅黑", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB",sans-serif;
}
input,textarea{
  font-family: "SourceHanSansSC-Regular", "Microsoft Yahei", "微软雅黑",  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB",sans-serif;
}
h1, h2, h3, h4, h5, h6, form, div, p, i, img, ul, li, ol, table, tr, td, th, fieldset, figure, label, legend, button, input {
  outline: none;
  margin: 0;
  padding: 0;
}

/*IE6的字体问题*/
input, textarea {
  box-sizing: border-box;
  border: none;
  resize: none;
  padding-left: 12px;
  font-weight: normal;
  font-size: 14px;
  font-family: "Microsoft Yahei", Arial, sans-serif;
  border-radius: 5px;
  color: $font_color;
  outline-style: none;

}

input {
  height: 30px;
  line-height: initial !important;
}

textarea {
  padding-top: 10px;
  overflow: hidden;
}

input:hover {
  border-color: #fc9d9a;
}

textarea:hover {
  border-color: #fc9d9a;
}

input:focus {
  outline-style: none;
  border-color: $basicColorRed;
}

textarea:focus {
  outline-style: none;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 14px;
  font-weight: normal;
}
a, button, input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline-style: none;
}
/*	去掉ie浏览器下输入框右侧的小图标*/
::-ms-clear, ::-ms-reveal {
  display: none;
}
input:hover::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #7f8c8d;
}

input:hover:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #7f8c8d;
}

input:hover::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #7f8c8d;
}

input:hover:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #7f8c8d;
}

textarea:hover::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #7f8c8d;
}

textarea:hover:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #7f8c8d;
}

textarea:hover::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #7f8c8d;
}

textarea:hover:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #7f8c8d;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #bdc3c7;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #bdc3c7;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #bdc3c7;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  //font-size: 14px;
  color: #bdc3c7;
}

/*移除数字类型的文本框后边默认图标*/
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/*火狐浏览器下移除number类型图标*/
button {
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  color: #95a5a6;
  font-family: "Microsoft Yahei", "微软雅黑", sans-serif;
}

button:hover {
  background-color: #fc9d9a;
  border-color: #fc9d9a;
  color: #fff;
}

li {
  list-style: none;
}

img {
  border: none;
}

a {
  text-decoration: none;
  color: #7f8c8d;
}

a:hover {
  color: $basicColorRed;
}
/*定义一些常用的类*/
.margin {
  margin: 0 auto;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.disinle {
  display: inline-block;
  vertical-align: top;
}

.hide {
  display: none !important;
}

.toolong {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.color-white {
  color: white;
}

.radius {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.border {
  border: 1px solid #d7dcde;
}

.clearfix:after {
  display: block;
  height: 0;
  content: "";
  visibility: hidden;
  clear: both;
}

.clearfix {
  zoom: 1
}

.fontsize8 {
  /* font-size: 8px !important; */
  transform: scale(.67);
  color: #bdc3c7;
}

.PhotoPic-classinfo .fontsize8 {
  transform: scale(.9);
  color: #7f8c8d;
}

@for $i from 6 through 20 {
  .font-#{2*$i} {
    font-size: 2px * $i;
    //color: #2c3e50;
  }
}

@for $i from 9 through 25 {
  .line-#{2*$i} {
    line-height: 2px * $i;
  }
}

.positionrel {
  position: relative;
}

.positionabs {
  position: absolute;
}

.positionfix {
  position: fixed;
}

.positionsta {
  position: static;
}

a.jhkcolora {
  color: $basicColorRed;
}

.font12-c8 {
  font-size: 12px;
  color: #bdc3c7;
}

.font14-c5 {
  font-size: 14px;
  color: #2c3e50;
}

.headarraybox {
  position: absolute;
  height: 20px;
  background-color: transparent;
  top: -20px;
  left: 0;
  right: 0;
  overflow: hidden;
}

.headarraybox:before {
  content: "";
  display: block;
  width: 24px !important;
  height: 24px !important;
  background-color: #fff;
  //border-top: 1px solid #d7dcde;
  //border-left: 1px solid #d7dcde;
  -webkit-transform: rotate(45deg) skew(8deg, 8deg) !important;
  transform: rotate(45deg) skew(8deg, 8deg) !important;
  border-top: none !important;
  border-left: none !important;
  position: absolute;
  top: 11px !important;
  left: 50%;
  margin-left: -5px;
  -webkit-border-radius: 20%;
  -moz-border-radius: 20%;
  border-radius: 20%;
}

//带箭头弹出框/下拉框盒子
.DropDownBox {
  position: absolute;
  width: 100%;
  top: 36px;
  display: none;
  z-index: 999999;

  .arrowbox {
    height: 8px;
  }

  .DropDownArrow {
    width: 8px;
    height: 8px;
    background-color: #fff;
    position: absolute;
    top: 4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 9;
    border-top: 1px solid #d7dcde;
    border-left: 1px solid #d7dcde;
  }

  .DropDownContent {
    width: 100%;
    max-height: 222px;
    padding: 5px 0;
    padding-bottom: 15px;
    background-color: #fff;
    font-size: 14px;
    color: #2c3e50;
    border-radius: 5px;
    border: 1px solid #d7dcde;

    .DropDownChecked {
      background-color: #fe4365;
      color: #fff;
    }
  }

  .DropDownContentlist {
    font-size: 14px;
    color: #7f8c8d;
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  .DropDownContentlist:hover {
    background-color: #fc9d9a;
    color: #fff;
    font-size: 14px;
  }
}

.headarraybox {
  width: 100%;
  height: 20px;
  background-color: transparent;
  position: absolute;
  top: -20px;
  left: 0;
}

.headarraybox:before {
  content: "";
  display: block;
  width: 24px !important;
  height: 24px !important;
  background-color: #fff;
  //border-top: 1px solid #fff;
  //border-left: 1px solid #fff;
  -webkit-transform: rotate(45deg) skew(8deg, 8deg) !important;
  transform: rotate(45deg) skew(8deg, 8deg) !important;
  position: absolute;
  top: 11px;
  left: 50%;
  margin-left: -5px;
  -webkit-border-radius: 20%;
  -moz-border-radius: 20%;
  border-radius: 20%;
}

.Versiontitle {
  font-size: 18px;
  color: #2c3e50;
  height: 58px;
  line-height: 58px;
  margin-bottom: 15px;
  margin-top: -30px;
}

.loadings {
  background-image: url("#{$cdn_wimg_path}/p/images/loading02.gif");
  background-repeat: no-repeat;
  background-position: 50%;
}
a.jhkcolora, .red {
  color: $main_color !important;
}

span.bold-undeline {
  font-weight: bold;
  text-decoration: underline;
}


#app{
  min-width: 1280px;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
  &>div.pageContent{
    width: 100vw;
    min-width: 1280px;
    >.pageGroupContent{
      width: 100vw;
      min-width: 1024px;
    }
  }
}
.masonry::after{
  display: block;
  content: " ";
  float: none;
  clear: both;
  overflow: hidden;
}
:root{
  --g-color-themeRed: #FF4D4D;
  --g-colorGray: #B4B4B4;
  --g-colorGray50: #F8F8F8;
  --g-colorGray60:#E5E5E5;
  --g-colorGray100: #AAAAAA;
  --g-colorGray150: #E5E5E5;
  --g-colorGray200: #767676;
  --g-colorGray250: #333333;
  --g-colorGray300: #393939;
  --g-colorGray350: #000000;
  --g-colorGray400: #303030;
}
/** 收藏按钮 */
.btnCollectHasWord {
  font-size: 14px;
  padding-left: 30px;
  height: 100%;
  border: 1px solid transparent;
  border-radius: 4px;
  line-height: 30px;
  display: flex;
  align-items: center;
  img,.btnCollectHasWord-icon {
    width: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
    fill: #767676;
  }
}

/** 下载按钮 */
.btnDownloadHasWord {
  font-size: 14px;
  padding-left: 34px;
  height: 100%;
  border-radius: 4px;
  line-height: 30px;
  .download_img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    width: 18px;
    img{
      position: static;
    }
    .downLoad_bottom {
      width: 18px;
    }
    .downLoad_arrow {
      margin-top: 2px;
      width: 9px;
      animation: arrow .2s infinite alternate;
    }
  }
  .ellipsis{
    letter-spacing: -3.4px;
  }
  .downProgress{
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 20%;
    height: 3px;
    background: #FF4D4D;
  }
  img {
    width: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
  }
}
.newaddFreeBtn{
  padding-left: 46px;
  &.loading{
    padding-left: 28px;
  }
  img{
    width: 16px;
    left: 28px;
  }
}
@keyframes arrow {
  0%{
    transform: translateY(-3px);
  }
  100%{
    transform: translateY(0);
  }
}

div.el-dialog__wrapper{
  overflow-y: scroll;
}
div.el-dialog__header{
  button.el-dialog__headerbtn{
    width: 24px;
    height: 24px;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-dialog__close::before{
      content: '';
      background: url("../assets/images/dialog_close_icon_2.svg") center center no-repeat;
      opacity: 0.8;
      width: 16px;
      height: 16px;
      display: inline-block;
    }
    &:hover{
      .el-dialog__close::before{
        background: url("../assets/images/dialog_close_icon_2.svg") center center no-repeat;
        opacity: 1;
      }
    }
  }
}
body .v-modal {
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
}
.el-dialog__wrapper{
  transform: translate3d(0,0,100px);
}
.el-dialog.bbw-dialog-center {
  border-radius: 8px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body{
    padding: 0;
  }
}

.hitbox.tips_v1{
  width: 344px;
  height: 88px;
  background-color: #303030;
  border-radius: 44px;
  margin-left: -172px;
}


.loading-box{
  width: 100%;
  text-align: center;
  font-size: 0;
  .loading-box-icon{
    font-size: 0;
    display: inline-block;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-name: rotate;
    animation-timing-function: linear;
    transform-origin: center center;
  }
}


.openBoardTip .tip-content{
  font-size: 14px;
}

